/* website: 0000-widgets
 * created at 2018-06-29 10:10 by fprovost
 * This is a default stylesheet to fallback on
 */

// Import all makes and organization styles files

@import "../utils/icons.scss";

@import "../utils/includes.scss";

// ========
// Fonts
// ========

$fontFamily: 'Open Sans', arial;
$boldFontFamily: 'Open Sans', arial;
$boldFontWeight: 700;
$titleFontFamily: $boldFontFamily;
$titleFontWeight: $boldFontWeight;
$isUppercase: uppercase;

// ========
// Colors
// ========

$textColor: #333333;
$linkColor: #00a2fd;
$labelColor: #aaaaaa;
$formColor: #333333;
$utilColor: #00a2fd;
$priceColor: #ff2841;
$lightColor: #aaaaaa;
$btnColorAlpha: #ffffff;
$btnColorBeta: #ffffff;
$btnColorStep: #ffffff;
$titleColor: #16181f;
$titleSecondaryColor: #ffffff;
$subtitleColor: #333333;
$subtitleSecondaryColor: #ffffff;
$successColor: #2DB253;
$warningColor: #f7941d;
$errorColor: #ff3526;

$bgPrimaryColor: #ffffff;
$bgSecondaryColor: #f2f2f2;

$bgBtnAlpha: #e7253c;
$colorBtnAlpha: #fff;
$bgBtnBeta: #21232e;
$colorBtnBeta: #fff;
$colorBtnOutlineAlpha: #555555;
$colorBtnOutlineBeta: #00a2fd;
$paddingButtonPrimary: 16px 14px;
$paddingButtonSecondary: 11px 15px;
$borderRadiusButton: 5px;

$borderRadiusForm: 0px;
$borderColorForm: #000;
$borderSizeForm: 1px solid;

$calculatorTabsColor: #16181e;
$calculatorTabsTextColor: #dedede;
$calculatorCloseTabs: #505050;
$calculatorCloseTabsColor: #686869;
$calculatorMainColor: #ffffff;
$calculatorButton: #ff1b43;

$garageBackground: #ffffff;
$garageTextColor: #333333;
$garageCarNoteColor: #f8f8f8;

$bgTopbarLogin: #21232e;
$colorTopbarLogin: #c8c9cb;

$progressBarTitleBG: #00426b;
$progressBarTitleColor: #ffffff;
$progressBarTitleCloseBG: #ffffff;
$progressBarTitleCloseColor: #00426b;
$progressBarCloseButtonBG: #005e99 ;
$progressBarCloseButtonColor: #ffffff;

$paymentCalculatorClosedBG: #171921;
$paymentCalculatorClosedColor: #e8e8e8;
$paymentCalculatorPanelBG: #21232E;
$paymentCalculatorTermWrapperBG: #363a4d;
$paymentCalculatorTermWrapperColor: #aaaaaa;
$paymentCalculatorTermSelectedWrapperBG: #ffffff;
$paymentCalculatorTermSelectedColor: #ffffff;
$paymentCalculatorTermBG: #252835;
$paymentCalculatorTermColor: #aaaaaa;

// ========
// Styles
// ========

.widget-sr{
    @include clearfix;
    width: 100%;

    font-family: $fontFamily;
    font-size: 14px;
    color: $textColor;
    background-color: $bgPrimaryColor;
    &.Checkout{
        background-color: $bgSecondaryColor
    }


    // **********************
    // Header B&B
    // **********************
    @include headerBuildBuy;

    // **********************
    // Barre de progression
    // **********************
    @include progressBar;

    // **********************
    // Formulaires
    // **********************
    @include forms;

    // **********************
    // Par sections
    // **********************
    @include paymentCalculator;
    @include checkout;
    @include sidebarsummary;
    @include login;
    @include garage;
    @include protections;

    @include titles;
    @include buttons;
    @include links;
    @include generics;
    @include baseline;
}
